<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="row col-lg-6 col-sm-6 my-auto">
              <h4 class="mb-0 lg-bld">Manage Banners</h4>
            </div>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-12 float-none">
                <div
                  style="cursor: pointer"
                  class="text-right form-group d-flex justify-content-end "
                  v-show="accessObject?.is_add == 1"
                >
                  <a class="fill-btn cursor-pointer mx-2" v-on:click="showAddBannerPopup"
                    >+ Add
                  </a>
                  <a class="fill-btn cursor-pointer" v-on:click="deleteAllPopup"
                    >Delete
                  </a>
                </div>
                
                <div class="card border mb-0 radius-15">
                  <div class="card-body p-0 pb-4">
                    <div class="table-responsive">
                      <div class="dataTables_wrapper dt-bootstrap4">
                        <table
                          class="table table-striped table-bordered dataTable"
                          style="width: 100%"
                          role="grid"
                          aria-describedby="example_info"
                        >
                          <thead>
                            <tr role="row">
                              <th>Sr. No.</th>
                              <th>Image</th>
                              <th>Type</th>
                              <th v-show="accessObject?.is_delete == 1">
                                <input
                                  type="checkbox"
                                  id="deleteAll"
                                  v-show="accessObject?.is_delete == 1"
                                  v-on:click="selectAllData($event)"
                                  true-value="1"
                                  false-value="0"
                                  class="mr-2"
                                  v-model="is_select"
                                  :class="is_select == '0' ? 'a' : 'b'"
                                />
                                Select
                              </th>
                              <th v-show="accessObject?.is_edit == 1">Action</th>
                            </tr>
                          </thead>
                          <tbody v-show="!loader">
                            <tr v-for="(data, index) in list" :key="index">
                              <td style="text-align: left">
                                {{
                                  (currentPage - 1) * itemsPerPage + index + 1
                                }}
                              </td>
                              <td>
                                <img
                                  :src="
                                    data.image ? data.image : '/images/placeholder.gif'
                                  "
                                  width="40"
                                  height="40"
                                  style="border-radius: 100px"
                                />
                              </td>
                              <td>{{ $helperService.getTitleCase(data?.type) }}</td>
                              <td v-show="accessObject?.is_delete == 1">
                                <input
                                  type="checkbox"
                                  :id="'delete_' + data.id"
                                  v-show="accessObject?.is_delete == 1"
                                  v-on:click="deleteData($event, data)"
                                  v-model="data.is_select"
                                  true-value="1"
                                  false-value="0"
                                  class="mr-2"
                                  :class="data.is_select == '1' ? 'a' : 'b'"
                                />
                              </td>
                              <td v-show="accessObject?.is_edit == 1">
                                <a
                                  class="fill-btn btn-style cursor-pointer"
                                  v-on:click="showUpdateBannerPopup(data)"
                                  v-show="accessObject?.is_edit == 1"
                                  >Edit</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row mx-0 mb-4">
                      <div class="col-lg-12 mt-5 text-center">
                        <div class="page-bottom-pagination text-right">
                          <Pagination
                            @page-change="pageChange"
                            @items-per-page-change="itemsPerPageChange"
                            ref="listPagination"
                          />
                        </div>
                      </div>
                    </div>
                    <ErrorComponent @retry="getList()" ref="errorComponent" />
                    <TextModal ref="textModal">
                      <div class="modal-header">
                        <h5 class="modal-title">
                          {{ details.id ? "Edit" : "Add" }} Banner
                        </h5>
                        <button
                          type="button"
                          class="close"
                          v-on:click="$refs.textModal.closeModal"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <Form @submit="uploadImage()">
                        <div class="modal-body">
                          <div class="col-lg-12 col-sm-12 mb-3">
                            <div class="text-center mb-4">
                              <img
                                :src="
                                  details.image && details.image != null
                                    ? details.image
                                    : '/images/placeholder.gif'
                                "
                                id="preview"
                                class="img-thumbnail"
                                style="border-radius: 100px; width: 120px; height: 120px"
                              /><br />
                              <div id="msg"></div>
                              <div id="image-form" style="margin: 0 auto; width: 90px">
                                <input
                                  type="file"
                                  class="file"
                                  id="pickFile"
                                  v-on:change="onChange($event)"
                                  accept="image/*"
                                  style="display: none"
                                />
                                <div class="input-group my-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled
                                    placeholder="Upload File"
                                    id="file"
                                    style="display: none"
                                  />
                                  <div class="changePic" style="bottom: -25px">
                                    <label for="pickFile"> Change</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button id="save-button" class="fill-btn text-center">
                            Upload
                          </button>
                        </div>
                      </Form>
                    </TextModal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view banner</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteAllRecord()" ref="deleteModal" />
    <TextModal ref="editModal">
      <div class="modal-header">
        <h5 class="modal-title">Select Language</h5>
        <button type="button" class="close" v-on:click="$refs.editModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form @submit="editProduct">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 mb-3">
              <div class="form-group col-md-6">
                <label for="inputRole">Language</label>
                <span class="text-danger">*</span>
                <Field
                  as="select"
                  class="form-control"
                  id="inputRole"
                  name="name_language"
                  v-model="editLangCode"
                  rules="required:language"
                >
                  <option value="">Select Language</option>
                  <option
                    v-for="(data, index) in languageList"
                    :key="index"
                    :value="data.code"
                  >
                    {{ data.title }} ({{ data.code }})
                  </option>
                </Field>
                <ErrorMessage name="name_language" class="text-danger" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="fill-btn" id="save-button">Go</button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.editModal.closeModal"
          >
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal";
import Pagination from "@/components/Pagination";
export default {
  name: "BannerPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    DeleteModal,
    TextModal,
    Pagination,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Banners"),
      loader: false,
      isShowAccessDeniedMessage: false,
      file: undefined,
      image: "",
      currentPage: 1,
      itemsPerPage: 10,
      searchObj: {
        keyword: "",
      },
      details: {
        id: "",
      },
      list: [],
      deleteList: [],
      is_select: "0",
      languageList: [],
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getlanguageList();
    var tabChangePage = this.$shareService.getMapValue("banner_page");
    this.getList(tabChangePage);
  },
  methods: {
    getlanguageList() {
      this.$api
        .getAPI({
          _action: "/language/all",
        })
        .then((res) => {
          this.languageList = res;
          for (var i = 0; i < this.languageList.length; i++) {
            this.languageList[i].value = "";
          }
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("banner_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {
      let itemLimit = this.$shareService.getMapValue("product_attribute_item_limit");
     if(itemLimit != null){
      this.itemsPerPage = itemLimit;
     }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/banner/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count,this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No banner available");
            this.$refs.errorComponent.updateLoader(false);
          }
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_select = "0";
          }
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateLoader(false);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    editProduct() {
      this.$refs.editModal.closeModal();
      this.$router.push("/edit-banner/" + this.editid + "/" + this.editLangCode);
    },
    showBannerPopup() {
      this.details = {};
      this.$refs.textModal.showModal();
    },
    showAddBannerPopup() {
      this.$router.push("/add-banner");
    },

    showUpdateBannerPopup(data) {
      this.$shareService.setMapValue("banner_page", this.currentPage);
      this.getlanguageList();
      this.editid = data.id;
      this.$refs.editModal.showModal();
    },
    deleteAllPopup() {
      this.$refs.deleteModal.showModal(
        "Delete Banners",
        "Are you sure you want to delete " + this.deleteList.length,
        +" banners"
      );
    },
    deleteAllRecord() {
      this.$api
        .postAPI({
          _action: "/delete/banner-all",
          _body: { list: this.deleteList },
        })
        .then((res) => {
          if (res) {
            this.deleteList = [];
            this.is_select = "0";

            this.$refs.deleteModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Banner",
        "Are you sure you want to delete ",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/banner/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    deleteData(event, data) {
      this.$shareService.setMapValue("banner_page", this.currentPage);
      if (event.target.checked) {
        this.deleteList.push(data.id);
      } else {
        for (var i = 0; i < this.deleteList.length; i++) {
          if (this.deleteList[i] == data.id) {
            this.deleteList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);
    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.deleteList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.deleteList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
    uploadImage() {
      if (this.details.id) {
        var obj = {};
        obj.id = this.details.id;
      }
      if (!this.file) {
        this.$notify({
          type: "error",
          text: "Image is already uploaded,Please select new image for upload",
        });
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/banner",
          _key: "image",
          _file: this.file,
          _body: obj,
          _buttonId: "save-button",
        })
        .then(() => {
          this.$refs.textModal.closeModal();
          this.getList(this.currentPage)
          this.$notify({
            type: "success",
            text: "Image is uploaded successfully",
          });
        });
    },
  },
};
</script>

