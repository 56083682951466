<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Report</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <ul class="nav nav-pills m-3">
              <li class="nav-item cursor-pointer">
                <a class="nav-link" data-toggle="tab" v-on:click="setStatus('ACTIVE')"
                  :class="{ active: currentTab == 'ACTIVE' }">
                  <span>Active</span></a>
              </li>
              <li class="nav-item cursor-pointer">
                <a class="nav-link" data-toggle="tab" v-on:click="setStatus('DELETED')"
                  :class="{ active: currentTab == 'DELETED' }">
                  <span>Deleted</span></a>
              </li>
            </ul>
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="getList(currentPage)" placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(currentPage)">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter"
                  v-show="searchObj.keyword">Reset</a>
              </div>
              <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group">
                <a v-show="accessObject?.is_delete == 1" class="fill-btn cursor-pointer"
                  style="padding: 11px; margin-right: 16px" v-on:click="deleteAllPopup">Delete
                </a>
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">

                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>
                            User Name
                          </th>
                          <th>Type </th>
                          <th>Sub Type</th>
                          <th>
                            Comment
                          </th>
                          <th v-show="accessObject?.is_delete == 1">
                            <input type="checkbox" id="deleteAll" v-show="accessObject?.is_delete == 1"
                              v-on:click="selectAllData($event)" true-value="1" false-value="0" class="mr-2"
                              v-model="is_select" :class="is_select == '0' ? 'a' : 'b'" /> Select
                          </th>
                          <th v-show="accessObject?.is_edit == 1">Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align:left;">
                            {{
                              (currentPage - 1) * itemsPerPage + index + 1
                            }}
                          </td>
                          <td style="text-align:left;">
                            {{ data?.user?.name }}
                          </td>
                          <td style="text-align:left;">
                            {{ $helperService.getTitleCase(data?.type) }}
                          </td>
                          <td style="text-align:left;">
                            {{ $helperService.getTitleCase(data?.sub_type) }}
                          </td>
                          <td style="text-align:left;">
                            {{ data?.comment }}
                          </td>
                          <td v-show="accessObject?.is_delete == 1">

                            <input v-if="data.code != 'EN'" type="checkbox" :id="'delete_' + data.id"
                              v-show="accessObject?.is_delete == 1" v-on:click="deleteData($event, data)"
                              v-model="data.is_select" true-value="1" false-value="0" class="mr-2"
                              :class="data.is_select == '1' ? 'a' : 'b'" />
                          </td>
                          <td v-show="accessObject?.is_edit == 1">
                            <a class="fill-btn btn-style cursor-pointer" v-show="accessObject?.is_view == 1"
                              v-on:click="showViewDetails(data)">View</a>
                            <a v-if="currentTab == 'ACTIVE'" class="fill-btn btn-style cursor-pointer"
                              v-on:click="deleteSinglePopup(data?.id)" v-show="accessObject?.is_delete == 1">Delete</a>
                            <a v-if="currentTab == 'DELETED'" class="fill-btn btn-style cursor-pointer"
                              v-on:click="showConfirmation('ACTIVE', data?.id)"
                              v-show="accessObject?.is_delete == 1">Restore</a>
                            <a v-if="currentTab == 'DELETED'" class="fill-btn btn-style cursor-pointer"
                              v-on:click="showConfirmation('DELETED', data?.id)"
                              v-show="accessObject?.is_delete == 1">Permanent Delete</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList()" ref="errorComponent" />

              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view language</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteAllRecord" ref="deleteModal" />
    <DeleteModal @remove="deleteSingleRecord" ref="deleteSingleModal" />
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">View Details</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" v-if="details?.sub_type === 'USER' && details?.type === 'USER'">
          <div class="col-lg-6">
            <p class="text-secondary">Name</p>
            <h6 class="bolder fw-200"> {{ details?.user?.name }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Email</p>
            <h6 class="bolder fw-200"> {{ details?.user?.email }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Mobile Number</p>
            <h6 class="bolder fw-200"> {{ details?.user?.mobile_number }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">About us</p>
            <h6 class="bolder fw-200"><span v-if="details?.user?.about_us != null"> {{ details?.user?.about_us
                }}</span> <span v-else>-</span></h6>
          </div>
        </div>
        <div class="row" v-if="details?.sub_type === 'POST_COMMENT' && details?.type === 'POST'">
          <div class="col-lg-6">
            <p class="text-secondary">Post Title</p>
            <h6 class="bolder fw-200"> {{ details?.post_comment?.post?.title }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Post Description</p>
            <h6 class="bolder fw-200"> {{ details?.post_comment?.post?.description }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Post Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.post_comment != null">{{ details?.post_comment?.comment
                }}</span> <span v-else>-</span></h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported User</p>
            <h6 class="bolder fw-200"> <span v-if="details?.user != null">{{ details?.user?.name }}</span> <span v-else>-</span> </h6>
          </div>
          <hr>
          <div class="col-lg-12" v-if="details?.post_comment?.post?.image != null">
            <p class="text-secondary">Post Image</p>
            <img :src="details?.post_comment?.post?.image" class="img-fluid" width="250">
          </div>
          <div class="col-lg-12" v-else-if="details?.post_comment?.post?.video != null">
            <p class="text-secondary">Post Video</p>
            <video width="320" height="240" controls>
              <source :src="details?.post_comment?.post?.video">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-lg-12" v-else>
            <p class="text-secondary">Post Image</p>
            -
          </div>
        </div>
        <div class="row" v-if="details?.sub_type === 'POST_COMMENT_REPLY' && details?.type === 'POST'">
          <div class="col-lg-6">
            <p class="text-secondary ">Post Title</p>
            <h6 class="bolder fw-200"> {{ details?.post_comment_reply?.post_comment?.post?.title }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Post Description</p>
            <h6 class="bolder fw-200"> {{ details?.post_comment_reply?.post_comment?.post?.description }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Post Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.post_comment_reply != null">{{ details?.post_comment_reply?.post_comment?.comment
                }}</span> <span v-else>-</span></h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Reply</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported User</p>
            <h6 class="bolder fw-200"> <span v-if="details?.user != null">{{ details?.user?.name }}</span> <span v-else>-</span> </h6>
          </div>
          <hr>
          <div class="col-lg-12" v-if="details?.post_comment_reply?.post_comment?.post?.image != null">
            <p class="text-secondary">Post Image</p>
            <img :src="details?.post_comment_reply?.post_comment?.post?.image" class="img-fluid" width="250">
          </div>
          <div class="col-lg-12" v-else-if="details?.post_comment_reply?.post_comment?.post?.video != null">
            <p class="text-secondary">Post Video</p>
            <video width="320" height="240" controls>
              <source :src="details?.post_comment_reply?.post_comment?.post?.video">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-lg-12" v-else>
            <p class="text-secondary">Post Image</p>
            -
          </div>
        </div>

        <div class="row" v-if="details?.sub_type === 'PRODUCT_COMMENT' && details?.type === 'PRODUCT'">
          <div class="col-lg-6">
            <p class="text-secondary">Product Name</p>
            <h6 class="bolder fw-200"> {{ details?.product_review?.product?.name }}</h6>
          </div>
          
          <div class="col-lg-6">
            <p class="text-secondary">Product Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.product_review != null">{{ details?.product_review?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Reported Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported User</p>
            <h6 class="bolder fw-200"> <span v-if="details?.user != null">{{ details?.user?.name }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-12" v-if="details?.product_review?.product?.image != null">
            <p class="text-secondary">Product Image</p>
            <img :src="details?.product_review?.product?.image" class="img-fluid" width="250">
          </div>
          <div class="col-lg-12" v-else-if="details?.product_review?.product?.video != null">
            <p class="text-secondary">Product Video</p>
            <video width="320" height="240" controls>
              <source :src="details?.product_review?.product?.video">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-lg-12" v-else>
            <p class="text-secondary">Product Image</p>
            -
          </div>
        </div>
        <div class="row" v-if="details?.sub_type === 'PRODUCT_COMMENT_REPLY' && details?.type === 'PRODUCT'">
          <div class="col-lg-6">
            <p class="text-secondary">Product Name</p>
            <h6 class="bolder fw-200"> {{ details?.product_review_reply?.product_review?.product?.name }}</h6>
          </div>
          
          <div class="col-lg-6">
            <p class="text-secondary">Product Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.product_review_reply != null">{{ details?.product_review_reply?.product_review?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Reply</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported User</p>
            <h6 class="bolder fw-200"> <span v-if="details?.user != null">{{ details?.user?.name }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-12" v-if="details?.product_review_reply?.product_review?.product?.image != null">
            <p class="text-secondary">Product Image</p>
            <img :src="details?.product_review_reply?.product_review?.product?.image" class="img-fluid" width="250">
          </div>
          <div class="col-lg-12" v-else-if="details?.product_review_reply?.product_review?.product?.video != null">
            <p class="text-secondary">Product Video</p>
            <video width="320" height="240" controls>
              <source :src="details?.product_review_reply?.product_review?.product?.video">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-lg-12" v-else>
            <p class="text-secondary">Product Image</p>
            -
          </div>
        </div>
        <div class="row" v-if="details?.sub_type === 'POST' && details?.type === 'POST'">
          <div class="col-lg-6">
            <p class="text-secondary">Post Title</p>
            <h6 class="bolder fw-200"> {{ details?.post?.title }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Post Description</p>
            <h6 class="bolder fw-200"> {{ details?.post?.description }}</h6>
          </div>
          <!-- <div class="col-lg-6">
            <p class="text-secondary">Post Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment
                }}</span> <span v-else>-</span></h6>
          </div> -->
          <div class="col-lg-6">
            <p class="text-secondary"> Reported Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported User</p>
            <h6 class="bolder fw-200"> <span v-if="details?.user != null">{{ details?.user?.name }}</span> <span v-else>-</span> </h6>
          </div>
          <hr>
          <div class="col-lg-12" v-if="details?.post?.image != null">
            <p class="text-secondary">Post Image</p>
            <img :src="details?.post?.image" class="img-fluid" width="250">
          </div>
          <div class="col-lg-12" v-else-if="details?.post?.video != null">
            <p class="text-secondary">Post Video</p>
            <video width="320" height="240" controls>
              <source :src="details?.post?.video">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-lg-12" v-else>
            <p class="text-secondary">Post Image</p>
            -
          </div>
        </div>
        <div class="row" v-if="details?.sub_type === 'PRODUCT' && details?.type === 'PRODUCT'">
          <div class="col-lg-6">
            <p class="text-secondary">Product Name</p>
            <h6 class="bolder fw-200"> {{ details?.product?.name }}</h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary">Product Description</p>
            <h6 class="bolder fw-200"> {{ details?.product?.description }}</h6>
          </div>
          <!-- <div class="col-lg-6">
            <p class="text-secondary">Post Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment
                }}</span> <span v-else>-</span></h6>
          </div> -->
          <div class="col-lg-6">
            <p class="text-secondary"> Reported Comment</p>
            <h6 class="bolder fw-200"> <span v-if="details?.comment != null">{{ details?.comment }}</span> <span v-else>-</span> </h6>
          </div>
          <div class="col-lg-6">
            <p class="text-secondary"> Reported User</p>
            <h6 class="bolder fw-200"> <span v-if="details?.user != null">{{ details?.user?.name }}</span> <span v-else>-</span> </h6>
          </div>
          <hr>
          <div class="col-lg-12" v-if="details?.product?.image != null">
            <p class="text-secondary">Product Image</p>
            <img :src="details?.product?.image" class="img-fluid" width="250">
          </div>
          <div class="col-lg-12" v-else-if="details?.product?.video != null">
            <p class="text-secondary">Product Video</p>
            <video width="320" height="240" controls>
              <source :src="details?.product?.video">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-lg-12" v-else>
            <p class="text-secondary">Product Image</p>
            -
          </div>
        </div>

      </div>
      <div class="modal-footer pb-0">
        <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px">
          Close
        </button>
       
        <button v-if="currentTab === 'ACTIVE'" type="button" class="fill-btn btn-style cursor-pointer"  v-on:click="deleteSinglePopup(details?.id)"
          style="width: 20%; border-radius: 5px">
          Delete
        </button>
        <button v-if="currentTab === 'DELETED'" type="button" class="fill-btn btn-style cursor-pointer"  v-on:click="showConfirmation('DELETED', details?.id)"
          style="width: 36%; border-radius: 5px">
          Permanent Delete
        </button>
        
      </div>
    </TextModal>
    <TextModal ref="confirmation">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" v-on:click="closeConfirmation()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-sm-12 mb-3">
            Are you sure you want to <span v-if="action_status == 'ACTIVE'">restore report?</span> <span
              v-else>permanent delete report?</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="fill-btn" @click="updateStatusApiCall()" id="confirmation-btn-new">Yes</button>
        <button type="button" class="fil-btn" id="cancel-button" v-on:click="closeConfirmation()">
          Cancel
        </button>
      </div>
    </TextModal>

  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
export default {
  name: "LanguagePage",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    DeleteModal,
    Pagination,
    TextModal
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Language"),
      loader: true,
      isShowAccessDeniedMessage: false,
      offset: -1,
      itemsPerPage: 10, searchObj: {
        keyword: "",
      },
      details: {
      },
      list: [],
      deleteList: [],
      details: {},
      is_select: '0',
      currentPage: 1,
      delete_id: null,
      currentTab: "ACTIVE",
      confirmation_id: null,
      action_status: null
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    var tabChangePage = this.$shareService.getMapValue("report_page");
    this.getList(tabChangePage)
  },
  methods: {
    showViewDetails(data) {
      this.details = data;
      console.log("this.reviewDetail", this.details);
      this.$refs.textModal.showModal();
      // this.$refs.reviewForm.resetForm();
    },
    showPracticePopup() {
      this.$refs.textModal.showModal();
    },

    setStatus(status) {
      this.searchObj.status = status;
      this.currentTab = status;
      this.getList(1);
    },


    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("report_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {
      let itemLimit = this.$shareService.getMapValue("report_item_limit");
      if (itemLimit != null) {
        this.itemsPerPage = itemLimit;
      }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/report/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (this.$refs.listPagination) {
            if (res.count != null && res.count > 0) {
              this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No report available");
            this.$refs.errorComponent.updateLoader(false);
          }
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_select = "0";
          }
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateLoader(false);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
    showAddLanguagePopup() {
      this.details = {}; document.getElementById("preview").src = "/images/placeholder.gif";
      this.$refs.textModal.showModal();
      this.$refs.brandForm.resetForm();
    },
    deleteAllPopup() {
      this.$refs.deleteModal.showModal(
        "Delete Reports",
        "Are you sure you want to delete " + this.deleteList.length,
        + " reports"
      );
    },
    deleteAllRecord() {
      this.$api
        .postAPI({
          _action: "/delete/report-all",
          _body: { "list": this.deleteList }
        })
        .then((res) => {
          if (res) {
            this.deleteList = [];
            this.is_select = '0';

            this.$refs.deleteModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

    deleteData(event, data) {
      if (event.target.checked) {
        this.deleteList.push(data.id);
      } else {
        for (var i = 0; i < this.deleteList.length; i++) {
          if (this.deleteList[i] == data.id) {
            this.deleteList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);


    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.deleteList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.deleteList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
    showUpdateLanguagePopup(index) {
      this.$shareService.setMapValue("report_page", this.currentPage);
      this.details = {};
      this.details.title = this.list[index].title;
      this.details.code = this.list[index].code;
      this.details.id = this.list[index].id;
      this.details.flag = this.list[index].flag;
      this.details.status = this.list[index].status;
      this.$refs.textModal.showModal();
    },
    save() {
      var obj = {
        title: this.details.title,
        code: this.details.code,
        status: this.details.status,
      };
      if (this.details.id) {
        obj.id = this.details.id;
      }
      this.$api
        .postAPI({
          _action: "/language",
          _body: obj,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (this.details.id) {
            this.uploadImage(this.details.id);
          } else {
            this.uploadImage(res.language_id);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$refs.textModal.closeModal();
        this.getList(this.currentPage);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/language/photo",
          _key: "flag",
          _file: this.file,
          _body: {
            id: id,
          },
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.getList(this.currentPage);
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.file = undefined;
        })
        .catch(() => { });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.currentPage, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(this.currentPage);
    },

    deleteSinglePopup(id) {
      this.$refs.textModal.closeModal();  
      this.$shareService.setMapValue("report_page", this.currentPage);
      this.delete_id = id;
      this.$refs.deleteSingleModal.showModal(
        "Delete Report",
        "Are you sure you want to delete",
      );
    },
    deleteSingleRecord() {
      this.$api
        .deleteAPI({
          _action: "delete/report/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteSingleModal.closeModal();
            this.delete_id = null;
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showConfirmation(status, confirmation_id) {
      this.$refs.confirmation.closeModal();
      this.$shareService.setMapValue("report_page", this.currentPage);
      this.action_status = status;
      this.confirmation_id = confirmation_id;
      this.details = {};
      this.$refs.confirmation.showModal();
    },
    closeConfirmation() {
      this.$refs.confirmation.closeModal();
      this.action_status = null;
      this.confirmation_id = null;
    },
    updateStatusApiCall() {
      this.$api
        .postAPI({
          _action: "/update-status-report/" + this.confirmation_id,
          _body: { status: this.action_status },
          _buttonId: "confirmation-btn-new"
        })
        .then((res) => {
          if (res) {
            this.closeConfirmation();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

  },
};
</script>
